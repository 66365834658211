import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { SecurityService } from '@services/common/security.service';
import { LoginService } from '@services/login.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AbstractComponent } from './components/abstract/abstract.component';
import { SettingsService } from './services/settings.service';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent extends AbstractComponent {
    public loadingMessage?: string;

    constructor(
        private platform: Platform,
        private screenOrientation: ScreenOrientation,
        private statusBar: StatusBar,
        private splashScreen: SplashScreen,
        private settingsService: SettingsService,
        private securityService: SecurityService,
        private ngZone: NgZone,
    ) {
        super();

        this.translateService.setDefaultLang('fr');
        this.translateService.use('fr');
        this.initializeApp();
    }

    public initializeApp(): void {

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.ngZone.run(() => {
                this.navController.navigateRoot('/home').then(() => this.navController.navigateForward(this.getPathFromUrl(event.url)));
            });
        });

        this.platform.ready().then(async () => {
            await this.settingsService.init()
            if (this.capacitorService.isNative()) {
                this.pianoAnalyticsService.initCapacitor();
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
                this.statusBar.styleDefault();
               this.statusBar.show();
            }

            if (this.capacitorService.isNative()) {
                this.splashScreen.hide();
            } else {
                this.pianoAnalyticsService.init();
            }
        });
    }

    public logout(): void {
        this.securityService.logout().then(() => {
            this.navController.navigateRoot('/login');
        });
    }

    private getPathFromUrl(url : string) : string {
        if(url.match("/mobile")) {
            return url.split("/mobile").pop();
        }
        return url.split(".fr").pop();
    }
}
