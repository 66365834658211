import { Subscription } from 'rxjs';

import { Directive, OnDestroy } from '@angular/core';
import { CapacitorService } from '@app/services/common/capacitor.service';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from '@services/common/app-injector.service';
import { PianoAnalyticsService } from '@services/piano-analytics.service';
import { ToastService } from '@services/toast.service';

@Directive()
export class AbstractComponent implements OnDestroy {
    public translateService: TranslateService;
    public toastService: ToastService;
    public capacitorService: CapacitorService;
    public alertController: AlertController;
    public modalController: ModalController;
    public pianoAnalyticsService: PianoAnalyticsService;
    public navController: NavController;
    protected subscriptions: { [key: string]: Subscription } = {};
    protected timeouts: { [key: string]: any } = {};
    protected intervals: { [key: string]: any } = {};

    constructor() {
        const injector = AppInjector.getInjector();
        this.translateService = injector.get(TranslateService);
        this.toastService = injector.get(ToastService);
        this.alertController = injector.get(AlertController);
        this.modalController = injector.get(ModalController);
        this.capacitorService = injector.get(CapacitorService);
        this.pianoAnalyticsService = injector.get(PianoAnalyticsService);
        this.navController = injector.get(NavController);
    }

    ngOnDestroy() {
        // Subscriptions
        Object.keys(this.subscriptions).forEach(key => {
            const subscription = this.subscriptions[key];
            if (subscription && subscription.unsubscribe) {
                subscription.unsubscribe();
            }
        });

        // Timeouts
        Object.keys(this.timeouts).forEach(key => {
            const timeout = this.timeouts[key];
            if (timeout) {
                clearTimeout(timeout);
            }
        });

        // Intervals
        Object.keys(this.intervals).forEach(key => {
            const interval = this.intervals[key];
            if (interval) {
                clearInterval(interval);
            }
        });
    }

    public goBack(root: boolean = false): void {
        if (root) {
            this.navController.navigateBack('home');
        } else {
            this.navController.back();
        }
    }

    protected handleError(err: any): void {
        console.log(err);
    }
}
