// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const pkg = require('../../package.json');

export const environment = {
    production: false,
    client: 'sncf',
    projet: 'sam',
    module: 'mobile',
    version: pkg.version,
    dateMaj: pkg.datemaj
};
