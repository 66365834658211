import { ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component } from '@angular/core';

import { AbstractComponent } from '../abstract/abstract.component';

@Component({
    selector: 'loading',
    templateUrl: 'loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent extends AbstractComponent implements OnChanges {

    @Input()
    public message: string;

    @Input()
    public show: boolean;

    constructor( public el: ElementRef) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.show) {
            if (changes.show.currentValue === true && changes.show.previousValue !== true) {
                clearTimeout(this.timeouts.hiddenTimeout);
                clearTimeout(this.timeouts.hideTimeout);
                this.el.nativeElement.className = 'show';
            } else if (changes.show.currentValue === false && changes.show.previousValue !== false) {
                clearTimeout(this.timeouts.hiddenTimeout);
                clearTimeout(this.timeouts.hideTimeout);
                this.timeouts.hideTimeout = window.setTimeout( () => {
                    this.el.nativeElement.className = '';
                    this.timeouts.hiddenTimeout = window.setTimeout( () => this.el.nativeElement.className = 'hidden', 250 );
                }, 250 );
            }
        }
    }
}
