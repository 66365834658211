import { User } from '@models/user.model';

export class Settings {
    public user: User;
    public lignes: string[];
    public postes: string[];
    public etablissement: string[];
    public isTransilient: boolean;
    public isFirstConnection: boolean;

    constructor(lignes: string[] = [], etablissement: string[] = [], postes: string[] = [], user?: User, isTransilient: boolean = true, isFirstConnection: boolean = true) {
        this.lignes = lignes;
        this.etablissement = etablissement;
        this.postes = postes;
        this.user = user;
        this.isTransilient = isTransilient;
        this.isFirstConnection = true;
    }

}
