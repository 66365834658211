import { Injectable } from '@angular/core';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private toastCtrl: ToastController, private fileOpener: FileOpener) {
    }

    public async show(message: string, duration: number = 3000): Promise<void> {
        const toast = await this.toastCtrl.create({
            message,
            duration,
            position: 'bottom'
        });
        return toast.present();
    }

    public async showOpenFile(message: string, path: string, mimeType: string): Promise<void> {
        const toast = await this.toastCtrl.create({
          message,
          duration: 0,
          position: 'bottom',
          buttons: [
                {
                    text: 'Ouvrir',
                    handler: () => {
                        this.fileOpener.showOpenWithDialog(path, mimeType).catch(() => {
                            this.show(`Impossible d'ouvrir le fichier`);
                        })
                    },
                },
            ],
        });
        toast.present();
      }

}
