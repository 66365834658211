import { finalize, from, lastValueFrom, Observable, throwError, timer } from 'rxjs';
import { catchError, delay, map, retry } from 'rxjs/operators';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecurityService } from '@app/services/common/security.service';
import { NavController } from '@ionic/angular';
import { ConfigService } from '@services/common/config.service';
import { OidcService } from '@app/services/common/oidc.service';
import { Capacitor } from '@capacitor/core';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    public static redirectInProgress = false;

    public readonly tag: string = '[HttpConfigInterceptor]';

    constructor(private securityService: SecurityService, private navController: NavController, private configService: ConfigService, private oidcService: OidcService) { }

    // This interceptor injects Authorization bearer header only when running on native platform.
    // In order to achieve this, the interceptor needs a valid token.
    // This is why the interceptor is converted to a promise, because we need async calls to get the valid token.
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next));
    }

    async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        // Injecting the token shall only happens on native platform when calling the SAM API
        // Any other request shall not get the token injected.
        const isAPICall = request.url.startsWith(this.configService.getConfig().BASE_API);
        if (isAPICall && Capacitor.isNativePlatform()) {
            console.log(this.tag, 'On android device, adding bearer token');
            const idToken = await this.oidcService.getIdToken();
            if (idToken && idToken !== '') {
                request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${idToken}`) });
            }
        }
        return lastValueFrom(next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            retry({
                count: 3, delay: (err: HttpErrorResponse) => {
                    const isServerError = err.status >= 500;
                    console.log(this.tag, 'Error : ', err);
                    return isServerError ? timer(250) : throwError(() => err);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                const backendUrl = this.configService.getConfig().BASE_API;
                const urlWithParams = request.urlWithParams;
                const remoteBackend = backendUrl && urlWithParams.indexOf(backendUrl) === 0;
                const localBackend = urlWithParams.indexOf(location.protocol + '//') !== 0;

                if (remoteBackend || localBackend) {
                    if (error.status === 403) {
                        if (!HttpConfigInterceptor.redirectInProgress) {
                            console.log('[HttpConfigInterceptor] Redirection vers la page de login ...');
                            HttpConfigInterceptor.redirectInProgress = true;
                            this.securityService.logout().then(() => {
                                setTimeout(() => {
                                    HttpConfigInterceptor.redirectInProgress = false;
                                    console.log("login 3")

                                    this.navController.navigateRoot('/login');
                                }, 200);
                            });
                        } else {
                            console.log('[HttpConfigInterceptor] Redirection déjà en cours ...');
                        }
                    }
                }
                return throwError(() => error);
            }),
        ));
    }
}
