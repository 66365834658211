import { Config } from '@models/config.model';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, BehaviorSubject } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import conf from '@app/conf/conf.js';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private static readonly APP_CONNECT_CONFIG_KEYS = [
        'ENVIRONMENT',
        'Imperceptible_FID_FF',
        'SERVICE_CLIENTID',
        'SERVICE_CLIENTSECRET',
        'SERVICE_REDIRECT_URL',
        'Authentication_Cert' //
    ]

    private TAG: string = '[ConfigService]';
    private config: Config;
    private http: HttpClient;
    private readonly ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }

    public async loadAppConfig(): Promise<Config> {
        console.log(this.TAG, 'Loading configuration from assets');
        this.config = await this.loadConfigFromJSON();

        if (Capacitor.isNativePlatform() && this.config.ENVIRONMENT !== 'local') {
            console.log(this.TAG, 'Not on a local device, trying to load configuration from AppConnect');
            this.loadFromAppConnectPlugin();
            await lastValueFrom(this.ready);
        }

        console.log(this.TAG, 'Configuration loaded');
        return this.config;
    }

    private async loadConfigFromJSON(): Promise<Config> {
        return Promise.resolve(conf)
    }

    private loadFromAppConnectPlugin(): void {
        const allKeys = ConfigService.APP_CONNECT_CONFIG_KEYS.join(' ');
        window.AppConnectPlugin.requestConfig(allKeys,
            (response: string) => {
                console.log(this.TAG, 'Configuration loaded from AppConnect successfully');
                const AppConnectConfig = JSON.parse(response);

                this.config = {
                    ...this.config,
                    SNCF_ID: {
                        ...this.config.SNCF_ID,
                        FEATURE_FLAG: AppConnectConfig.Imperceptible_FID_FF,
                        REDIRECT_URI: AppConnectConfig.SERVICE_REDIRECT_URL,
                        CLIENT_ID: AppConnectConfig.SERVICE_CLIENTID,
                        CLIENT_SECRET: AppConnectConfig.SERVICE_CLIENTSECRET,
                        PLATFORM: AppConnectConfig.ENVIRONMENT
                    }
                };

                this.ready.complete();
            },
            (err: any) => {
                console.error(this.TAG, err);
            });
        return;
    }

    public getConfig(): Config {
        return this.config;
    }
}
