import {Observable, catchError, map, tap} from 'rxjs';

import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Component, Injectable, NgModule} from '@angular/core';
import {Article} from '@models/article.model';
import {ArticleState} from '@models/enums/article-state.enum';
import {Etablissement} from '@models/etablissement.model';
import {EtablissementPEG} from '@models/etablissementPEG.model';
import {Gare} from '@models/gare.model';
import {Ligne} from '@models/ligne.model';
import {PostePEG} from '@models/postePEG.model';
import {Profil} from '@models/profil.model';
import {Rubrique} from '@models/rubrique.model';
import {SecteurPEG} from '@models/secteurPEG.model';
import {User, UtilisateurProfil} from '@models/user.model';
import {UtilisateurPEG} from '@models/utilisateurPEG.model';
import {ApiCrudService} from '@services/api-crud.service';
import {ConfigService} from '@services/common/config.service';
import {PdfViewerModule} from 'ng2-pdf-viewer';


@Injectable({
    providedIn: 'root',
})
export class ApiService {
    pdfSrc: Uint8Array;

    constructor(public configService: ConfigService, public apiCrudService: ApiCrudService, private http: HttpClient) {
    }

    public authenticate(accessToken: unknown): Observable<User> {
        const uri = 'auth/authenticate/oidc';
        return this.apiCrudService.post(uri, accessToken, true);
    }

    ////// UTILISATEUR
    public getUtilisateur(byPassCatch: boolean = false): Observable<User> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/me`;
        return byPassCatch ? this.apiCrudService.getBypassCatch(uri, true) : this.apiCrudService.get(uri, true);
    }

    ////// ARTICLES

    public getArticleById(articleId): Observable<Article> {
        const uri = `${ApiCrudService.ENTITY_ARTICLES}/${articleId}`;
        return this.apiCrudService.get(uri);
    }

    public likeOrDislikeArticle(articleId, state: ArticleState): Observable<Article> {
        const uri = `${ApiCrudService.ENTITY_ARTICLES}/${articleId}/${state}`;
        return this.apiCrudService.post(uri, {});
    }

    public getNbArticlesPublies(): Observable<number> {
        const uri = `${ApiCrudService.ENTITY_ARTICLES}/nombrePublies`;
        return this.apiCrudService.get(uri);
    }

    public getTopArticles(): Observable<Article[]> {
        const uri = `${ApiCrudService.ENTITY_ARTICLES}/top`;
        return this.apiCrudService.get(uri);
    }

    public getTopActualitesWithFilters(ligneIds: string[] = []): Observable<Article[]> {
        const body: any = {};
        body.lignes = ligneIds;
        body.postes = JSON.parse(localStorage.getItem('currentPoste'));
        body.etablissements = JSON.parse(localStorage.getItem('currentEtablissement'));
        const data = {...body, hideLoader: true};
        const uri = `${ApiCrudService.ENTITY_ARTICLES}/top`;
        return this.apiCrudService.post(uri, data);
    }

    public refreshFavoris(data): Observable<Article[]> {
        const uri = `${ApiCrudService.ENTITY_ARTICLES}/refreshFavorites`;
        return this.apiCrudService.post(uri, data);
    }

    /** ACTUALITES **/
    public getActualites(body: any, isTransilient: boolean): Observable<Article[]> {
        const data = { ...body, hideLoader: true };
        const uri = `${ApiCrudService.ENTITY_ACTUALITES}`;
        return this.apiCrudService.postArticle(uri, data, false, null, isTransilient);
    }


    public getActualitesRefresh(body: any, isTransilient: boolean): Observable<{ depublies: Article[]; modifies: Article[]; publies: Article[] }> {
        const uri = `${ApiCrudService.ENTITY_ACTUALITES}/refresh`;
        return this.apiCrudService.postArticleRefresh(uri, body, false, null, isTransilient);
    }

    /** RUBRIQUES **/

    public getRubriques(ligneIds: string[], posteIds: string[], etablissementIds: string[]): Observable<Rubrique[]> {
        const data = {lignes: ligneIds, postes: posteIds, etablissements: etablissementIds, hideLoader: true};
        const uri = `${ApiCrudService.ENTITY_RUBRIQUES}`;
        return this.apiCrudService.post(uri, data);
    }

    /** LIGNES **/

    public getLignes(): Observable<Ligne[]> {
        const uri = `${ApiCrudService.ENTITY_LIGNES}`;
        return this.apiCrudService.get(uri);
    }

    /** ETABLISSEMENT **/

    public getEtablissements(): Observable<Etablissement[]> {
        const uri = `${ApiCrudService.ENTITY_ETABLISSEMENTS}`;
        return this.apiCrudService.get(uri);
    }

    /** PROFIL **/

    public getProfil(): Observable<Profil> {
        const uri = `${ApiCrudService.ENTITY_PROFIL}`;
        return this.apiCrudService.get(uri, true);
    }

    public setProfil(profil: UtilisateurProfil): Observable<Profil> {
        const uri = `${ApiCrudService.ENTITY_PROFIL}`;
        return this.apiCrudService.post(uri, profil, true);
    }

    /** PRESENCE EN GARE **/
    public getEtablissementsPEG(): Observable<EtablissementPEG[]> {
        const uri = `${ApiCrudService.ENTITY_ETABLISSEMENTS_PEG}`;
        return this.apiCrudService.get(uri);
    }

    public getPostesPEG(): Observable<PostePEG[]> {
        const uri = `${ApiCrudService.ENTITY_POSTES_PEG}`;
        return this.apiCrudService.get(uri);
    }

    public getSecteursPEG(): Observable<SecteurPEG[]> {
        const uri = `${ApiCrudService.ENTITY_SECTEURS_PEG}`;
        return this.apiCrudService.get(uri);
    }

    public getUserIdentifier(userIdentifier: string): Observable<UtilisateurPEG> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEURS_PEG}/${userIdentifier}`;
        return this.apiCrudService.get(uri);
    }

    public createUtilisateur(utilisateur: UtilisateurPEG): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEURS_PEG}`;
        return this.apiCrudService.post(uri, utilisateur);
    }

    public updateUtilisateur(utilisateurId: string, utilisateur: UtilisateurPEG): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEURS_PEG}/${utilisateurId}`;
        return this.apiCrudService.put(uri, utilisateur);
    }

    public updatePresenceEnGare(utilisateurId: string, codeGare: string, presence: boolean): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEURS_PEG}/${utilisateurId}/presence?code_gare=${codeGare}`;
        return this.apiCrudService.post(uri, {codeGare, presence});
    }

    public addGareIntervention(utilisateurId: string, codeGare: string): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEURS_PEG}/${utilisateurId}/gares/${codeGare}`;
        return this.apiCrudService.post(uri, {});
    }

    public getGaresAutocomplete(filtre: string): Observable<Gare[]> {
        const params = new HttpParams().append('search', filtre);
        const uri = `${ApiCrudService.ENTITY_GARES_PEG}/autocomplete`;
        return this.apiCrudService.get(uri, false, params);
    }

    /** FILE **/
    public downloadFile(url: string): Observable<any> {
        let params: HttpParams = new HttpParams();
        params = params.append('responseType', 'blob');
        params = params.append('observe', 'response');
        return this.apiCrudService.getFile(url, params);
    }

    /** Download PDF web **/
    public downloadPDFWeb(articleId: string): any {
        const uri = `${ApiCrudService.BACKEND_URL}api/mobile/generatePdf?articleId=` + articleId;
        console.log("URI: " + uri),
            this.http.post(uri, null,
                {responseType: 'arraybuffer'})
                .subscribe((response: ArrayBuffer) => {
                    const blob = new Blob([response], {type: 'application/pdf'});

                    // Create object URL from the blob
                    const url = window.URL.createObjectURL(blob);

                    // Create a link element to trigger the download
                    const link = document.createElement('a');
                    link.href = url;
                    const timestamp = new Date().getTime(); // Get current timestamp in milliseconds
                    const fileName = `${articleId}_${timestamp}_article.pdf`;
                    console.log("Article name: " + fileName);
                    link.setAttribute('download', fileName);

                    // Append the link to the body
                    document.body.appendChild(link);

                    // Trigger the download
                    link.click();

                    // Clean up the object URL after opening the PDF
                    window.URL.revokeObjectURL(url);
                });
    }

    /** Download PDF mobile **/
    async downloadPDFMobile(articleId: string): Promise<ArrayBuffer> {
        const uri = `${ApiCrudService.BACKEND_URL}api/mobile/generatePdf?articleId=${articleId}`;
        const header = new HttpHeaders({
            'Content-Type': 'application/pdf',
            'Accept': 'application/pdf'
        });
        const response = await this.http.post(uri, null, {headers : header, responseType: 'arraybuffer'}).toPromise();
        return response as ArrayBuffer;
    }

    /** MAIL **/
    public sendMail(body): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_MAIL}`;
        return this.apiCrudService.postEmail(uri, body);
    }

    /** RECHERCHE **/
    //TODO: Refacto : rediriger les requêtes get et post qui sont dans le articlesservice.
    // ----------------------------------------------  WIP
    public get(value: string, params: string = '', headers): Observable<any> {
        const uri = `${ApiCrudService.ROOT_URL_WITHOUT_MOBILE}`;
        return this.apiCrudService.get(uri);
    }

    public post(value: string, params: string, body): Observable<any> {
        const uri = `${ApiCrudService.ROOT_URL_WITHOUT_MOBILE}`;
        return this.apiCrudService.post(uri, body);
    }
}
