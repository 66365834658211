import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    static isAppBooting: boolean = false;

    constructor(private appVersion: AppVersion) {}

    public capitalizeFirstLetter(str: any): string {
        if (str === null || str === undefined) {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public getAppId(): Promise<string> {
        return this.appVersion.getPackageName();
    }

    public getAppVersion(): string {
        return environment.version + (environment.production ? '-DEV' : '');
    }

    public getURLParam(name: string): string {
        const url = new URL(window.location.href);
        return url.searchParams.get(name);
    }

    public getFormattedDateTime(): string {
        const today = new Date();
        const y = today.getFullYear();
        const m = today.getMonth() + 1;
        const d = today.getDate();
        const h = today.getHours();
        const min = today.getMinutes();
        const s = today.getSeconds();
        return y + '-' + m + '-' + d + '-' + h + '-' + min + '-' + s;
    }

    public isTelephoneValid(tel: string): boolean {
        return /\+33( )?(\(0\))?(6|7)(( )?\d\d){4}/.test(tel);
    }

    public isEmailValid(email: string): boolean {
        const regex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
        return regex.test(email);
    }

    public isFirefox() {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    }

    public isEdge() {
        return navigator.userAgent.toLowerCase().indexOf('edge') > -1;
    }

    public isChrome() {
        return navigator.appVersion.indexOf('Chrome/') > -1;
    }

    public normalizeString(text: string): string {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    /**
     * Méthode scrollIntoView native ne marche que pour le scroll vertical.
     *
     * Ajoute donc le scrollIntoView horizontal.
     *
     * @param container {HTMLElement} conteneur
     * @param el {HTMLElement} élément que l'on veut remettre dans la vue si en dehors
     * @param elPadding {number} padding de l'élément à remettre dans la vue
     * @param delta {number} décalage ajouté lors de la remise de l'élément dans la vue
     * @param vertical {boolean}
     */
    public scrollIntoViewHorizontal(container: HTMLElement, el: HTMLElement, elPadding: number = 20, delta: number = 40, vertical: boolean = false): void {
        if (el != null && container != null) {
            const w: number = container.offsetWidth;
            const tw: number = el.offsetLeft + el.offsetWidth + elPadding;
            // console.log("cw:", w, "-tw:", tw);
            if (tw > w + container.scrollLeft) {
                // console.log("RIGHT!!!", "-tw:", tw, "w + container.scrollLeft:", w + container.scrollLeft);
                container.scrollLeft = tw - w + elPadding + delta;
            } else if (el.offsetLeft - elPadding < container.scrollLeft) {
                // console.log("LEFT!!!", "elleft:", el.offsetLeft - elPadding, "container.scrollLeft:", container.scrollLeft);
                container.scrollLeft = el.offsetLeft - elPadding - delta;
            }
        }

        if (vertical === false) {
            return;
        }

        if (this.isFirefox() || this.isEdge()) {
            el.scrollIntoView({ behavior: 'smooth' });
        } else {
            el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }

    public clean(subs: { [key: string]: Subscription }): void {
        for (const key of Object.keys(subs)) {
            try {
                subs[key].unsubscribe();
                delete subs[key];
            } catch (e) {
                console.warn('Impossible de clear le subscribe ' + key, e);
            }
        }
    }

    blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    resolve(new TextDecoder('utf-8').decode(reader.result));
                }
            };
            reader.readAsDataURL(blob);
        });
    }
}
