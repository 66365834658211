import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';

export interface Download {
    content: Blob | null;
    progress: number;
    loadedInKb?: number;
    totalInKb?: number;
    state: 'PENDING' | 'IN_PROGRESS' | 'DONE' | 'ERROR';
}

export class Download {
    public static isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
        return event.type === HttpEventType.Response;
    }

    public static isHttpProgressEvent(event: HttpEvent<any>): event is HttpProgressEvent {
        return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
    }

    public static download(saver?: (b: Blob) => void): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
        return (source: Observable<HttpEvent<Blob>>) =>
            source.pipe(
                scan(
                    (download: Download, event: HttpEvent<Blob>): Download => {
                        console.log(download, event);
                        if (Download.isHttpProgressEvent(event)) {
                            return {
                                progress: event.total ? Math.round((100 * event.loaded) / event.total) : download.progress,
                                loadedInKb: event.loaded ? Math.round(event.loaded / 1000) : 0,
                                totalInKb: Math.round(event.total / 1000) || 0,
                                state: 'IN_PROGRESS',
                                content: null,
                            };
                        }
                        if (Download.isHttpResponse(event)) {
                            if (event.status !== 200) {
                                return {
                                    progress: 100,
                                    state: 'ERROR',
                                    content: event.body,
                                };
                            }
                            if (saver) {
                                saver(event.body);
                            }
                            return {
                                progress: 100,
                                state: 'DONE',
                                loadedInKb: event.body.size / 1000 || 0,
                                totalInKb: event.body.size / 1000 || 0,
                                content: event.body,
                            };
                        }
                        return download;
                    },
                    { state: 'PENDING', progress: 0, loadedInKb: 0, totalInKb: 0, content: null },
                ),
                distinctUntilChanged((a, b) => a.state === b.state && a.progress === b.progress && a.content === b.content),
            );
    }
}
