/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import { Article } from '@models/article.model';
import { User } from '@models/user.model';
import { CapacitorService } from '@app/services/common/capacitor.service';
import { ConfigService } from '@services/common/config.service';
import { UtilsService } from '@services/utils.service';
import { SlugifyPipe } from '@app/pipes/slugify.pipe';
import { StorageService } from './common/storage.service';
import { Config } from '@app/models/config.model';
import { SettingsService } from './settings.service';
import { Settings } from '@app/models/settings.model';

let _paq = window._paq || [];

@Injectable()
export class PianoAnalyticsService {
    private slugifyPipe: SlugifyPipe = new SlugifyPipe();
    private config: Config;
    private user: User;

    constructor(
        private capacitorService: CapacitorService,
        private settingsService: SettingsService,
        private utilsService: UtilsService,
        private storageService: StorageService,
        private configService: ConfigService
    ) {
        this.config = configService.getConfig();
        this.settingsService.getSettings().subscribe(async (settings: Settings) => {
            this.user = settings.user;
        })
    }

    public init(): void {
        try {
            console.log('[Analytics][Init] ', this.configService.getConfig().PIANO_ANALYTICS.ID);

            _paq = window._paq || [];

            _paq.push(['setSiteId', this.configService.getConfig().PIANO_ANALYTICS.ID]);

            const version = this.utilsService.getAppVersion();
            _paq.push(['setProperty', 'appVersion', version]);

            _paq.push(['trackPageView']);
            _paq.push([
                'sendEvent',
                'page.display',
                {
                    page: "StartApp",
                },
            ]);
            console.log('[Analytics][EndInit] ', this.configService.getConfig().PIANO_ANALYTICS.ID);
        } catch (e) {
            console.error('An error occured while initializing Piano Analytics');
        }
    }

    public initCapacitor(): void {
        try {
            console.log('[Analytics][Init] ', this.configService.getConfig().PIANO_ANALYTICS.ID);
            const version = this.utilsService.getAppVersion();
            _paq.push(['setSiteId', this.configService.getConfig().PIANO_ANALYTICS.ID]);
            _paq.push(['trackPageView']); // Track the initial page view
            _paq.push(['setProperty', 'appVersion', version]);
            _paq.push(['setUser', this.user?.id, 'user', true]);
            console.log('[Analytics][EndInit] ', this.configService.getConfig().PIANO_ANALYTICS.ID);
        } catch (error) {
            console.error('An error occured while initializing Piano Analytics');

        }
    }

    async cannotSendAnalytics(): Promise<boolean> {
        return await this.storageService.getAnalyticsStatus();
    }

    public async trackPageView(pageTitle: string, pagePath: string): Promise<void> {
        if (await this.cannotSendAnalytics()) {
            console.log('[DEACTIVATED][Analytics][trackPageView] ' + JSON.stringify(pageTitle));
            return;
        }
        console.log('[Analytics][trackPageView] ', pageTitle, pagePath);
        _paq.push([
            'sendEvent',
            'page.display',
            {
                page: pageTitle,
            },
        ]);
    }

    public async trackEvent(eventAction: string, data?: {[key: string]: any}): Promise<void> {
        if (await this.cannotSendAnalytics()) {
            console.log('[DEACTIVATED][Analytics][trackEvent] ' + JSON.stringify(eventAction));
            return;
        }
        console.log('[Analytics][trackEvent] ', eventAction);
        _paq.push([
            'sendEvent',
            eventAction,
            data
        ]);
    }

    public async trackClick(action: string, detail: string = '', deepDetail: string = '', deepestDetail: string = ''): Promise<void> {
        if (await this.cannotSendAnalytics()) {
            console.log('[DEACTIVATED][Analytics][trackPageView] ' + JSON.stringify(action));
            return;
        }
        console.log('[Analytics][trackClick] ', action);
        _paq.push([
            'sendEvent',
            'click.action',
            {
                click: action,
                click_chapter1: detail,
                click_chapter2: deepDetail,
                click_chapter3: deepestDetail,
            },
        ]);
    }

    public async trackEventWithProperties(eventProperties: { [key: string]: any }): Promise<void> {
        if (await this.cannotSendAnalytics()) {
            console.log('[DEACTIVATED][Analytics][trackEvent]');
            return;
        }
        const finalEventCategory = eventProperties.event_category || 'click.action';
        console.log('[Analytics][trackEvent]', eventProperties);

        _paq.push([
            'sendEvent',
            finalEventCategory ,
            eventProperties,
        ]);
    }

    public initUser(): void {
        const mymemocUserId = localStorage.getItem('mymemoc_id_user');

        const userId = this.capacitorService.isNative() ? `mode-mobile-${mymemocUserId}` : `mode-web-${mymemocUserId}`;

        _paq.push(['setUser', userId, 'user', true]);
    }

    public trackSiteSearch(keywords: string): void {
        if (keywords && keywords.length > 0) {
            this.trackPageView('Recherche avancée', `recherche-avancee?q=${keywords}`);
        }
    }

    public async trackArticle(article: Article, eventAction: string, eventLabel?: string, eventValue?: number): Promise<void> {
        if (await this.cannotSendAnalytics()) {
            console.log('[DEACTIVATED][Analytics][trackArticle] ' + JSON.stringify(article));
            return;
        }

        const rubrique = article.rubrique || 'Aucune rubrique';
        const typeArticle = article.typeArticle;
        const typeMemo = 'MEMO';
        const formattedTypeArticle = typeArticle === typeMemo ? `FICHE_${typeArticle}` : typeArticle;
        eventLabel = eventLabel === typeMemo ? formattedTypeArticle : eventLabel;
        const infosArticle = `Article_${this.slugifyPipe.transform(article.titre)}_${formattedTypeArticle}_${rubrique}`;

        console.log('[Analytics][trackArticle] ' + article.rubrique);
        _paq.push([
            'sendEvent',
            'click.action',
            {
                click: eventAction,
                click_chapter1: infosArticle,
                click_chapter2: eventLabel,
                click_chapter3: rubrique,
            },
        ]);
    }

    public async trackArticlesPublies(nbArticlesPublies: any): Promise<void> {
        if (await this.cannotSendAnalytics()) {
            console.log('[DEACTIVATED][Analytics][trackArticlesPublies] ' + nbArticlesPublies);
            return;
        }
        console.log('[Analytics][trackArticlesPublies] ' + nbArticlesPublies);
        _paq.push([
            'sendEvent',
            'articlePublished',
            {
                category: 'content',
                action: 'publish',
                label: 'Number of Articles',
                value: nbArticlesPublies,
            },
        ]);
    }

    public async trackLignes(): Promise<void> {
        const lignesLibelle: string[] = await this.settingsService.getSubscribedLigneLibelle();
        lignesLibelle.map(value => {
            this.trackLigneLibelle(value);
        });
    }

    public trackEtablissement(etablissement: string): void {
        this.trackEvent('etablissement', {label: etablissement});
    }

    public trackPoste(poste: string): void {
        this.trackEvent('poste', {label: poste});
    }

    public trackSecteur(secteur: string): void {
        this.trackEvent('secteur', {label: secteur});
    }

    public trackPhoneNumber(phoneNumber: string): void {
        this.trackEvent('phoneNumber', {label: phoneNumber});
    }

    private trackLigneLibelle(ligneLibelle: string): void {
        this.trackEvent('ligneLibelle', {label: ligneLibelle});
    }

    public trackUserIdAssociateToPhoneNumber(userId: string): void {
        this.trackEvent('userIdFromPhoneNumber',{label: userId});
    }

    public trackDeclarationGare(declare: string): void {
        this.trackEvent('isdeclaregare',{label: declare});
    }

    public trackContact(contact: string): void {
        this.trackEvent('contact', {label: contact});
    }

    public trackTransilien(isTransilien: boolean): void {
        this.trackEvent('transilien', {label: String(isTransilien)})
    }

    public trackPresenceEnGare(eventAction: string, gare: string) : void {
        this.trackEvent(eventAction, {label: gare})
    }

    public trackLigne(eventAction: string, ligne: string) : void {
        this.trackEvent(eventAction, {label: ligne})
    }

    public trackShareArticle() : void {
        this.trackEvent("ShareArticle", {label: "ShareArticle"})
    }

    public trackBibliotheque(label:string) : void {
        this.trackEvent("Bibliotheque", {label: label})
    }

    public async trackProfile(declare: string): Promise<void> {
        if (this.user) {
            this.trackEtablissement(this.user.etablissementLibelle);
            this.trackPoste(this.user.posteLibelle);
            this.trackSecteur(this.user.secteurLibelle);
            this.trackPhoneNumber(this.user.telephone);
            this.trackDeclarationGare(declare);
        }
    }
}
