/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { Storage } from '@ionic/storage-angular';
import { ArticleState } from '@models/enums/article-state.enum';
import { Settings } from '@models/settings.model';
import { from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public readonly ADMIN_ENV_KEY: string = 'ADMIN_ENV';

    public readonly KEY_ACTUALITES: string = 'actualites';
    public readonly KEY_ACTUALITES_LAST_REFRESH_DATE: string = 'actualitesLastRefreshDate';
    public readonly KEY_ANALYTICS: string = 'desactivation-analytics';
    public readonly KEY_EXPIRATION_PEG: string = 'expiration-peg';
    public readonly KEY_NON_DECLARATION_PEG: string = 'non-declaration-peg';
    public readonly KEY_FAVORIS: string = 'favoris';
    public readonly KEY_FAVORIS_LAST_REFRESH_DATE: string = 'favorisLastRefreshDate';
    public readonly KEY_RUBRIQUES_LAST_REFRESH_DATE: string = 'rubriquesLastRefreshDate';
    public readonly KEY_LAST_SEARCH_ARTICLES: string = 'lastSearchArticles';
    public readonly KEY_TOP_RESULTS: string = 'top';
    public readonly KEY_TOP_RESULTS_WITH_FILTERS: string = 'topWithFilters';
    public readonly KEY_LUS: string = 'lus';
    public readonly KEY_SETTINGS: string = 'settings';
    public readonly KEY_RUBRIQUES: string = 'rubriques';
    public readonly KEY_RUBRIQUES_ORDRE: string = 'ordre';
    public readonly KEY_PRESENCEENGARE_USERIDENTIFIER: string = 'presence_gare_user_identifier';
    public readonly KEY_USER_IDENTIFIER: string = 'user_identifier';
    public readonly KEY_LIGNES: string = 'lignes';
    public readonly KEY_ETABLISSEMENTS: string = 'etablissements';
    public readonly KEY_POSTES: string = 'postes';
    public readonly KEY_SECTEURS: string = 'secteurs';
    private readonly userIdKey = 'userId';

    public readonly KEY_ARTICLES: string = 'articles';

    private readonly MOBILE: string = 'mobile';

    constructor(private storage: Storage) { }

    /**
     * Retourne l'item dans le storage ayant pour clé la clé donnée en paramètre.
     *
     * @param key Le clé correspondant à l'item souhaité.
     * @returns Returns a promise with the value of the given key
     */
    get(key: string): Observable<any> {
        return from(this.storage.get(this.getKey(key)));
    }

    /**
     * Sauvegarde l'item dans le storage avec la clé donnée en paramètre.
     *
     * @param key Le clé correspondant à la valeur à sauvegarder.
     * @param value La valeur à sauvegarder.
     * @returns Returns a promise that resolves when the key and value are set
     */
    set(key: string, value: any): Observable<any> {
        return from(this.storage.set(this.getKey(key), value));
    }

    getUserId(): string | null {
        return localStorage.getItem(this.userIdKey);
    }

    setUserId(userId: string): void {
        localStorage.setItem(this.userIdKey, userId);
    }

    /**
     * Supprime un item du storage
     *
     * @param key La clé métier de l'item à supprimer.
     * @returns Returns a promise that resolves when the value is removed
     */
    remove(key: string): Observable<any> {
        return from(this.storage.remove(this.getKey(key)));
    }

    getAdminEnvionment(): Observable<any> {
        return this.get(this.getKey(this.ADMIN_ENV_KEY));
    }

    setAdminEnvionment(data: any): Observable<any> {
        return this.set(this.getKey(this.ADMIN_ENV_KEY), data);
    }

    getActualitesLastRefreshDate(): Observable<any> {
        return this.get(this.getKey(this.KEY_ACTUALITES_LAST_REFRESH_DATE));
    }

    setActualitesLastRefreshDate(data: any): Observable<any> {
        return this.set(this.getKey(this.KEY_ACTUALITES_LAST_REFRESH_DATE), data);
    }

    getActualites(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_ACTUALITES}`));
    }

    setActualites(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_ACTUALITES}`), data);
    }

    getRubriquesLastRefreshDate(): Observable<any> {
        return this.get(this.getKey(this.KEY_RUBRIQUES_LAST_REFRESH_DATE));
    }

    setRubriquesLastRefreshDate(data: any): Observable<any> {
        return this.set(this.getKey(this.KEY_RUBRIQUES_LAST_REFRESH_DATE), data);
    }

    getFavorisLastRefreshDate(): Observable<any> {
        return this.get(this.getKey(this.KEY_FAVORIS_LAST_REFRESH_DATE));
    }

    setFavorisLastRefreshDate(data: any): Observable<any> {
        return this.set(this.getKey(this.KEY_FAVORIS_LAST_REFRESH_DATE), data);
    }

    getFavoris(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_FAVORIS}`));
    }

    setFavoris(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_FAVORIS}`), data);
    }

    /** ARTICLES **/

    getArticle(articleId: string): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${articleId}`));
    }

    setArticle(articleId: string, data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${articleId}`), data);
    }

    getArticleLike(articleId: string): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${articleId}/${ArticleState.LIKE}`));
    }

    setArticleLike(articleId: string, data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${articleId}/${ArticleState.LIKE}`), data);
    }

    getArticlesLus(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_LUS}`));
    }

    setArticlesLus(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_LUS}`), data);
    }

    getLastSeachArticle(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_LAST_SEARCH_ARTICLES}`));
    }

    setLastSeachArticle(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_LAST_SEARCH_ARTICLES}`), data);
    }

    getTopArticles(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${this.KEY_TOP_RESULTS}`));
    }

    getTopActualitesWithFilters(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${this.KEY_TOP_RESULTS_WITH_FILTERS}`));
    }

    setTopActualitesWithFilters(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${this.KEY_TOP_RESULTS_WITH_FILTERS}`), data);
    }

    setTopArticles(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${this.KEY_TOP_RESULTS}`), data);
    }

    getArticleAimerStatus(articleId: string): Observable<boolean> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_ARTICLES}/${articleId}/aime`));
    }

    /** SETTINGS **/

    getSettings(): Observable<any> {
        return this.get(this.getKey(this.KEY_SETTINGS));
    }

    setSettings(data: any): Observable<Settings> {
        return this.set(this.getKey(this.KEY_SETTINGS), data);
    }

    /** RUBRIQUES **/

    getRubriques(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_RUBRIQUES}`));
    }

    setRubriques(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_RUBRIQUES}`), data);
    }

    clearRubriques(): Observable<any> {
        return this.remove(this.getKey(`${this.MOBILE}/${this.KEY_RUBRIQUES}`));
    }

    getOrdreRubrique(parent: any): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_RUBRIQUES}/${this.KEY_RUBRIQUES_ORDRE}/${parent}`));
    }

    setOrdreRubrique(parent: any, children: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_RUBRIQUES}/${this.KEY_RUBRIQUES_ORDRE}/${parent}`), children);
    }

    /** PRESENCE EN GARE **/

    getPresenceGareUserIdentifier(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_PRESENCEENGARE_USERIDENTIFIER}`));
    }

    setPresenceGareUserIdentifier(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_PRESENCEENGARE_USERIDENTIFIER}`), data);
    }

    clearPresenceGareUserIdentifier(): Observable<any> {
        return this.remove(this.getKey(`${this.MOBILE}/${this.KEY_PRESENCEENGARE_USERIDENTIFIER}`));
    }

    /** USER **/

    getUserIdentifier(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_USER_IDENTIFIER}`));
    }

    setUserIdentifier(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_USER_IDENTIFIER}`), data);
    }

    /** LIGNES **/
    getLignes(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_LIGNES}`));
    }

    setLignes(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_LIGNES}`), data);
    }

    /** ETABLISSEMENTS **/
    getEtablissements(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_ETABLISSEMENTS}`));
    }

    setEtablissements(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_ETABLISSEMENTS}`), data);
    }

    /** POSTES **/
    getPostes(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_POSTES}`));
    }

    setPostes(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_POSTES}`), data);
    }

    /** SECTEURS **/
    getSecteurs(): Observable<any> {
        return this.get(this.getKey(`${this.MOBILE}/${this.KEY_SECTEURS}`));
    }

    setSecteurs(data: any): Observable<any> {
        return this.set(this.getKey(`${this.MOBILE}/${this.KEY_SECTEURS}`), data);
    }

    async getAnalyticsStatus(): Promise<boolean> {
        const saved = await this.storage.get(this.KEY_ANALYTICS);
        if (saved === undefined || saved === null) {
            await this.setAnalyticsStatus(false);
        }
        return this.storage.get(this.KEY_ANALYTICS);
    }

    async setAnalyticsStatus(status: boolean): Promise<void> {
        return this.storage.set(this.KEY_ANALYTICS, status);
    }

    async getNonDeclarationPEGManager(): Promise<boolean> {
        const saved = await this.storage.get(this.KEY_NON_DECLARATION_PEG);
        if (saved === undefined || saved === null) {
            await this.setNonDeclarationPEGManager(false);
        }
        return this.storage.get(this.KEY_NON_DECLARATION_PEG);
    }

    async setNonDeclarationPEGManager(status: boolean): Promise<void> {
        console.log('setNonDeclarationPEGManager', status);
        return this.storage.set(this.KEY_NON_DECLARATION_PEG, status);
    }

    /// END LOCAL STORAGE

    /**
     * Retourne la clé de storage correspondant à l'application courante. On rajoute un préfixe pour éviter les conflits entre applications.
     *
     * @param key La clé que l'on veut transformer.
     * @returns La clé transformée.
     */
    private getKey(key: string): string {
        return `${environment.client}-${environment.projet}_${key}`;
    }
}
