import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkService } from '@app/services/network.service';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class OfflineInterceptor {
    constructor(private networkService: NetworkService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.networkService.isOnline() && req.urlWithParams.indexOf('http') === 0 && req.urlWithParams.indexOf('http://localhost') === -1) {
            const error = {
                status: 0,
                url: req.urlWithParams ?? req.url,
                statusText: 'NO_INTERNET',
                error: {
                    description: 'NO_INTERNET',
                },
            };
            return throwError(() => new HttpErrorResponse(error));
        }
        return next.handle(req);
    }
}
