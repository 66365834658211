import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigService} from '@services/common/config.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ApiCrudService {
    public static readonly MOBILE: string = 'mobile';
    public static BACKEND_URL: string;
    public static readonly ROOT_URL: string = 'api/mobile/';
    public static readonly ROOT_URL_WITHOUT_MOBILE: string = 'api/';

    public static readonly ENTITY_UTILISATEUR: string = 'users';
    public static readonly ENTITY_ARTICLES: string = 'articles';
    public static readonly ENTITY_ACTUALITES: string = 'actualites';
    public static readonly ENTITY_RUBRIQUES: string = 'rubriques';
    public static readonly ENTITY_LIGNES: string = 'lignes';
    public static readonly ENTITY_ETABLISSEMENTS: string = 'etablissements';
    public static readonly ENTITY_PROFIL: string = 'profil';
    public static readonly ENTITY_ETABLISSEMENTS_PEG: string = 'peg/etablissements';
    public static readonly ENTITY_POSTES_PEG: string = 'peg/postes';
    public static readonly ENTITY_SECTEURS_PEG: string = 'peg/secteurs';
    public static readonly ENTITY_UTILISATEURS_PEG: string = 'peg/utilisateurs';
    public static readonly ENTITY_GARES_PEG: string = 'peg/gares';
    public static readonly ENTITY_MAIL: string = 'email';
    public static readonly KEY_SEARCH: string = 'articles/search';
    public static readonly KEY_AUTOCOMPLETE: string = 'articles/search/autocomplete';
    public static readonly KEY_TOP_RESULTS: string = 'articles/top';
    public static readonly KEY_NB_ARTICLES_PUBLIES: string = 'articles/nombrePublies';
    public static readonly KEY_LAST_SEARCH_ARTICLES: string = 'lastSearchArticles';
    public static readonly ENTITY_DOWNLOAD_PDF_MOBILE: string = 'generatePdf?articleId=';

    // HttpClient without interceptor
    private httpClientWithout: HttpClient;

    constructor(public httpClient: HttpClient, public handler: HttpBackend, public configService: ConfigService) {
        this.httpClientWithout = new HttpClient(handler);
        ApiCrudService.BACKEND_URL = this.configService.getConfig().BASE_API;
    }

    /**
     *
     * Http call without interceptor
     *
     */

    public getWithout(path: string): Observable<any> {
        const url = this.buildUrl(path);
        return this.httpClientWithout.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    public postWithout(path: string, data: any, observe: any = 'body'): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getDefaultHeaders();
        return this.httpClientWithout.post(url, data, {headers, observe}).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     *
     * Http call with interceptor
     *
     */

    public get(path: string, withoutMobile: boolean = false, params: HttpParams = new HttpParams()): Observable<any> {
        const url = this.buildUrl(path, withoutMobile);
        const headers = this.getDefaultHeaders();
        return this.httpClient.get(url, {headers, params}).pipe(catchError(this.handleError.bind(this)));
    }

    public getBypassCatch(path: string, withoutMobile: boolean = false, params: HttpParams = new HttpParams()): Observable<any> {
        const url = this.buildUrl(path, withoutMobile);
        const headers = this.getDefaultHeaders();
        return this.httpClient.get(url, {headers, params});
    }

    public getFile(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getFileHeaders();
        return this.httpClient.get(url, {headers, params, responseType: 'blob'}).pipe(catchError(this.handleError.bind(this)));
    }

    public post(path: string, data: any, withoutMobile: boolean = false, observe: any = 'body', params: HttpParams = new HttpParams()): Observable<any> {
        const url = this.buildUrl(path, withoutMobile);
        const headers = this.getDefaultHeaders();
        return this.httpClient.post(url, data, {headers, params, observe}).pipe(catchError(this.handleError.bind(this)));
    }

    public postArticleRefresh(path: string, data: any, withoutMobile: boolean = false, observe: any = 'body', isTransilient: boolean = false): Observable<any> {
        const url = this.buildUrl(path, withoutMobile);
        const headers = this.getDefaultHeaders();

        // Construct HttpParams based on the boolean value
        let httpParams = new HttpParams();

        httpParams = httpParams.set('isTransilient', isTransilient);
        return this.httpClient.post(url, data, { headers, observe, params: httpParams }).pipe(catchError(this.handleError.bind(this)));
    }

    public postArticle(path: string, data: any, withoutMobile: boolean = false, observe: any = 'body', isTransilient: boolean = false): Observable<any> {
        const url = this.buildUrl(path, withoutMobile);
        const headers = this.getDefaultHeaders();

        // Construct HttpParams based on the boolean value
        let httpParams = new HttpParams();

        httpParams = httpParams.set('isTransilient', isTransilient);
        return this.httpClient.post(url, data, { headers, observe, params: httpParams }).pipe(catchError(this.handleError.bind(this)));
    }

    public postEmail(path: string, data: any, withoutMobile: boolean = true, observe: any = 'body'): Observable<any> {
        const url = this.buildUrl(path, withoutMobile);
        const headers = this.getDefaultHeaders();
        return this.httpClient.post(url, data, {headers, observe}).pipe(catchError(this.handleError.bind(this)));
    }

    public put(path: string, data: any): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getDefaultHeaders();
        return this.httpClient.put(url, data, {headers}).pipe(catchError(this.handleError.bind(this)));
    }

    public delete(path: string): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getDefaultHeaders();
        return this.httpClient.delete(url, {headers}).pipe(catchError(this.handleError.bind(this)));
    }

    public buildUrl(path: string, withoutMobile: boolean = false): string {
        return `${ApiCrudService.BACKEND_URL}${withoutMobile ? ApiCrudService.ROOT_URL_WITHOUT_MOBILE : ApiCrudService.ROOT_URL}${path}`;
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (!!error) {
            if (!!error.error && !!error.error.message) {
                return throwError(() => new Error(error.error.message));
            } else if (error.message) {
                return throwError(() => new Error(error.message));
            } else if (typeof error === 'string') {
                return throwError(() => new Error(error));
            }
        }
        return throwError(() => new Error('Une erreur est survenue.'));
    }

    private getDefaultHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    private getFileHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Response-Type': 'arrayBuffer',
        });
    }
}
