import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingComponent } from './loading.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
    ],
    declarations: [LoadingComponent],
    exports: [LoadingComponent]
})
export class LoadingModule {
}
