import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@services/common/auth-guard.service';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('@pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'contact',
        loadChildren: () => import('@pages/contact/contact.module').then(m => m.ContactPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'on-boarding',
        loadChildren: () => import('@pages/on-boarding/on-boarding.module').then(m => m.OnBoardingPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'mes-lignes',
        loadChildren: () => import('@pages/mes-lignes/mes-lignes.module').then(m => m.MesLignesPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'mes-etablissements',
        loadChildren: () => import('@pages/mon-etablissement/mon-etablissement.module').then(m => m.MesEtablissementsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'mon-profil',
        loadChildren: () => import('./pages/mon-profil/mon-profil.module').then(m => m.MonProfilPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'a-propos',
        loadChildren: () => import('./pages/a-propos/a-propos.module').then(m => m.AProposPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'select-gare',
        loadChildren: () => import('./pages/select-gare/select-gare.module').then(m => m.SelectGarePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'mobile/article/:id',
        loadChildren: () => import('./pages/article/article.module').then(m => m.ArticlePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'article/:id',
        loadChildren: () => import('./pages/article/article.module').then(m => m.ArticlePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'share-article',
        loadChildren: () => import('./pages/share-article/share-article.module').then(m => m.ShareArticlePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'article/previsu/:id',
        loadChildren: () => import('./pages/previsu/previsu.module').then(m => m.ArticlePrevisuPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'mobile/article/previsu/:id',
        loadChildren: () => import('./pages/previsu/previsu.module').then(m => m.ArticlePrevisuPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'article/previsu-partage/:id/:showMobileButton',
        loadChildren: () => import('./pages/previsu/previsu.module').then(m => m.ArticlePrevisuPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'article/previsu-pdf/:id',
        loadChildren: () => import('./pages/previsu-pdf/previsu-pdf.module').then(m => m.ArticlePrevisuPdfPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'mobile/article/previsu-pdf/:id',
        loadChildren: () => import('./pages/previsu-pdf/previsu-pdf.module').then(m => m.ArticlePrevisuPdfPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'rubrique',
        loadChildren: () => import('@pages/rubrique/rubrique.module').then(m => m.RubriquePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'search',
        loadChildren: () => import('@pages/search/search.module').then(m => m.SearchPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('@pages/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'init-error',
        loadChildren: () => import('@pages/init-error/init-error.module').then(m => m.InitErrorModule),
    },
    {
        path: '**',
        redirectTo: 'home',
    },
];

@NgModule({
    // imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: true })],
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
