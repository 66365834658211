import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Download } from '@app/utils/download';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Directory } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
import * as saveAs from 'file-saver';
import { Observable } from 'rxjs';

import { CapacitorService } from './common/capacitor.service';
import { ToastService } from './toast.service';

// import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DownloadService {

    constructor(
        private toastService: ToastService,
        private http: HttpClient,
        private androidPermissions: AndroidPermissions,
        private capacitorService: CapacitorService,
    ) {
    }

    checkRequirements(): Promise<boolean> {
        if (!this.capacitorService.isNative()) {
            return Promise.resolve(true);
        }
        return this.androidPermissions
            .checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
            .then(result => {
                console.log(result);
                if (result.hasPermission) {
                    return result.hasPermission;
                }
                return this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(res => {
                    console.log('Has permission?', res.hasPermission);
                    return res.hasPermission;
                });
            })
            .catch(err => {
                console.error(err);
                return this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(result => {
                    console.log('Has permission?', result.hasPermission);
                    return result.hasPermission;
                });
            });
    }

    download(url: string, filename: string): Observable<Download> {
        return this.http
            .get(url, {
                reportProgress: true,
                observe: 'events',
                responseType: 'blob',
            })
            .pipe(
                Download.download(async blob => {
                    if (this.capacitorService.isNative()) {
                        // const now = moment().format('YYYYMMDD_HHmmss');
                        // const now = new Date();
                        const now = Date.now();
                        console.log('date test', now);
                        filename = filename.replace(/(\.[\w\d_-]+)$/i, '_' + now + '$1');
                        console.log('Writing file to Download/' + filename);
                        write_blob({
                            path: `Download/${filename}`,
                            directory: Directory.ExternalStorage,
                            blob
                        }).then(uri => {
                            this.toastService.showOpenFile(`Le fichier a été enregistré dans votre dossier "Téléchargements"`, uri, 'application/pdf');
                        })
                        .catch(err => {
                            this.toastService.show(`Une erreur est survenue, veuillez réessayer ultérieurement`);
                            console.error(err);
                        });
                    } else {
                        console.log(blob, filename);
                        saveAs(blob, filename);
                        this.toastService.show(`Le fichier a été enregistré avec succès`);
                    }
                }),
            );
    }

}
