import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BandeauEnvComponent } from './bandeau-env.component';

@NgModule({
    imports: [CommonModule],
    declarations: [BandeauEnvComponent],
    exports: [BandeauEnvComponent],
})
export class BandeauEnvModule {
}
