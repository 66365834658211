import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications';
import {Role} from '@models/enums/role.enum';
import {ConfigService} from '@services/common/config.service';
import {FCM} from '@capacitor-community/fcm';

import {CapacitorService} from './common/capacitor.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public readonly tag: string = '[NotificationService]';

    private root: string;

    private emitter: EventEmitter<any> = new EventEmitter<any>();
    private subscribed: boolean = false;
    private registered: boolean = false;

    private subscribedChannels: string[] = [];
    private handleNotificationTimeout: number;

    constructor(
        private zone: NgZone,
        private capacitorService: CapacitorService,
        private configService: ConfigService,
    ) {
        if (!this.capacitorService.isNative()) {
            return;
        }
        this.root = configService.getConfig().ENVIRONMENT;

        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                PushNotifications.register().catch(err => console.log(err));
            } else {
                console.log(this.tag, 'PushNotifications not allowed');
            }
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token: Token) => {
            console.log(this.tag, 'Push registration success, token:' + token.value);

            FCM.getToken().then((result: { token: string }) => {
                console.log(this.tag, 'remoteToken', result.token);
            });
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (error: any) => {
            console.log(this.tag, 'Error on registration:', error);
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            console.log(this.tag, 'Push received:', notification);
            console.log(JSON.stringify(notification));
            this.zone.run(() => {
                const data = notification.data;
                this.handleNotification(data);
            });
        });

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed', (notificationAction: ActionPerformed) => {
            console.log(this.tag, 'Push action performed:', notificationAction);
            console.log('Received in background');
            console.log(JSON.stringify(notificationAction));
            this.zone.run(() => {
                const data = notificationAction.notification.data;

                this.handleNotification(data, true);
            });
        });
    }

    public register(ligneIds: string[], posteIds: string[],etablissementIds: string[], role: Role = Role.AGENT): void {
        console.log("now you are inside register ")
        if (!this.capacitorService.isNative()) {
            return;
        }
        console.log('this is the new posteIds' + JSON.stringify(posteIds))
        const tmpChannels = [];

        if (ligneIds && ligneIds.length > 0 && posteIds && posteIds.length > 0 && etablissementIds && etablissementIds.length > 0) {
            ligneIds.forEach((ligneId) => {
                etablissementIds.forEach((etablissementId) => {
                    posteIds.forEach((posteId) => {
                        tmpChannels.push(`${this.root}_mymemoc-${role}-lignes-${ligneId}-postes-${posteId}-etablissements-${etablissementId}`);
                        console.log(`${this.root}_mymemoc-${role}-lignes-${ligneId}-postes-${posteId}-etablissements-${etablissementId}`)
                    });
                });
            });
        }



        // console.info('Subscribe ', `${this.root}_mymemoc-${role}`);
        tmpChannels.push(`${this.root}_mymemoc-${role}`);

        let diff = false;
        for (const channel of tmpChannels) {
            if (this.subscribedChannels.indexOf(channel) === -1) {
                diff = true;
                break;
            }
        }

        if (!diff) {
            console.log('[NotificationService] register() - No diffs!');
            return;
        }

        console.log('[NotificationService] register()');

        this.unregister();

        this.subscribedChannels = [...tmpChannels];

        for (const channel of this.subscribedChannels) {
            // console.log(this.tag, 'Subscribe to channel:', channel);
            console.log( 'subscribe to channel:', channel);
            FCM.subscribeTo({topic: channel})
                .then(r => console.log(this.tag, `FCM subscribe sessionsTopic: ${channel}`))
                .catch(err => console.log(err));
        }
        this.registered = true;
    }

    public unregister() {
        if (!this.capacitorService.isNative()) {
            return;
        }

        console.log("yes there are differences between subscribed and current channels");
        if (this.subscribedChannels) {
            for (const channel of this.subscribedChannels) {
                // console.log(this.tag, 'Unsubscribe from channel:', channel);
                console.log( 'Unsubscribe from channel:', channel);
                FCM.unsubscribeFrom({topic: channel})
                    .then(r => console.log(this.tag, `FCM Unsubscribe sessionsTopic: ${channel}`))
                    .catch(err => console.log(err));
            }
            this.subscribedChannels.length = 0;
        }
        this.registered = false;
    }

    public subscribe(callback) {
        if (!this.capacitorService.isNative() || this.subscribed) {
            if (this.subscribed) {
                console.warn(this.tag, `déjà subscribe !`);
            } else {
                console.warn(this.tag, `subscribe impossible en dehors d'une application native !`);
            }
            return;
        }
        this.emitter.subscribe(callback);
        this.subscribed = true;
    }

    public isRegistered(): boolean {
        return this.registered;
    }

    private handleNotification(notification: any, wasTapped: boolean = false) {
        if (this.handleNotificationTimeout) {
            clearTimeout(this.handleNotificationTimeout);
        }
console.log("notification wastaped status "+wasTapped);
        if (this.subscribed ) {
            // Store the value of wasTapped in local storage
            console.log("either subscribed or wastapped works"+this.subscribed+wasTapped);
           // localStorage.setItem('wasTapped', JSON.stringify(true));
            this.emitter.emit({notification, wasTapped});
        } else {
            // On relance la fonction jusqu'à ce que les abonnements soient initialisés
            this.handleNotificationTimeout = window.setTimeout(() => {
                this.handleNotification(notification, wasTapped);
            }, 10);
        }
    }
}
