import { Injectable } from '@angular/core';
import { Role } from '@app/models/enums/role.enum';
import { Ligne } from '@app/models/ligne.model';
import { Settings } from '@app/models/settings.model';
import { User } from '@app/models/user.model';
import { BehaviorSubject, last, lastValueFrom } from 'rxjs';
import { ApiService } from './api.service';
import { StorageService } from './common/storage.service';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private readonly TAG: string = "[SettingsService]";
    private settingsObserver: BehaviorSubject<Settings> = new BehaviorSubject<Settings>(new Settings());
    private userObservers: BehaviorSubject<User>;
    private settings: Settings = new Settings();
    private initialized: boolean = false;

    constructor(
        private apiService: ApiService,
        private storageService: StorageService,
    ) {
        this.userObservers = new BehaviorSubject<User>(this.settings.user);
    }

    public isInitialized(): boolean {
        return this.initialized;
    }

    public async setIsFirstConnection(value: boolean) {
        if (this.settings.isFirstConnection !== value) {
            this.settings.isFirstConnection = value;
            await lastValueFrom(this.storageService.setSettings(this.settings));
            this.settingsObserver.next(this.settings);
        }
    }

    public getSettings(): BehaviorSubject<Settings> {
        return this.settingsObserver;
    }

    public getUser(): BehaviorSubject<User> {
        return this.userObservers;
    }

    public hasLigne(): boolean {
        return this.settings.lignes.length > 0;
    }

    public async addSubsrcribedLigne(ligne: Ligne) {
        this.settings.lignes.push(ligne.id);
        await lastValueFrom(this.storageService.setSettings(this.settings));
        this.settingsObserver.next(this.settings);
    }

    public async removeSubscribedLigne(ligne: Ligne) {
        const index = this.settings.lignes.indexOf(ligne.id);
        this.settings.lignes.splice(index, 1);
        await lastValueFrom(this.storageService.setSettings(this.settings));
        this.settingsObserver.next(this.settings);
    }

    public getSubscribedLigneIds(): string[] {
        return this.settings.lignes;
    }

    public async getSubscribedLigneLibelle(): Promise<string[]> {
        const lignesLibelle: string[] = [];
        const lignes = await lastValueFrom(this.storageService.getLignes());
        for (const m of lignes) {
            if (this.settings.lignes.includes(m.id)) {
                lignesLibelle.push(m.libelle);
            }
        }
        // Si aucune ligne sélectionnée, les considérons toutes cochées
        if (lignesLibelle.length === 0) {
            for (const m of lignes) {
                lignesLibelle.push(m.id);
            }
        }
        return lignesLibelle;
    }

    public getSubscribedPosteIds(): string[] {
        return this.settings.postes;
    }

    public async setSubscribedPosteIds(ids: string[]) {
        let updateRequired: boolean = ids.length !== this.settings.postes.length;

        for(const id of ids) {
            const found = this.settings.postes.find((v) => v === id);
            if (!found) {
                updateRequired = true;
                break;
            } 
        }
        if (updateRequired) {
            console.log(this.TAG, 'Updating subscribed postes');
            this.settings.postes = ids;
            await lastValueFrom(this.storageService.setSettings(this.settings));
            this.settingsObserver.next(this.settings);
        }
    }

    public async setUser(user: User) {
        this.settings.user = user;
        await lastValueFrom(this.storageService.setSettings(this.settings));

        this.userObservers.next(this.settings.user);
    }

    public getUserRole(): Role {
        return this.settings.user ? this.settings.user.role : null;
    }

    public isTransilient(): boolean {
        return this.settings.isTransilient;
    }

    public async setIsTransilient(isTransilient: boolean) {
        if (this.settings.isTransilient !== isTransilient) {
            console.log(this.TAG, 'Updating is transilient to: ', isTransilient);
            this.settings.isTransilient = isTransilient;
            await lastValueFrom(this.storageService.setSettings(this.settings));
            this.settingsObserver.next(this.settings);
        }
    }

    public getSubscribedEtablissementIds(): string[] {
        return this.settings.etablissement;
    }

    public async setSubscribedEtablissementIds(ids: string[]) {
        let updateRequired: boolean = ids.length !== this.settings.etablissement.length;
        ids.forEach((id) => {
            const found = this.settings.etablissement.find((v) => v === id);
            if (!found) updateRequired = true;
        })
        if (updateRequired) {
            this.settings.etablissement = ids;
            await lastValueFrom(this.storageService.setSettings(this.settings));
            this.settingsObserver.next(this.settings);
        }
    }

    public hasEtablissement(): boolean {
        return this.settings.etablissement.length > 0;
    }

    public async init(): Promise<void> {
        try {
            console.log(this.TAG, 'Initializing settings');
            const settings = await lastValueFrom(this.storageService.getSettings());
            if (settings) {
                this.settings = settings;
            }

            this.initialized = true;
            this.settingsObserver.next(this.settings);
            console.log(this.TAG, 'Settings initialized succesfully ', this.settings);
        } catch (err: unknown) {
            console.log(this.TAG, 'An error occured during settings initialization', err);
        }

        return;
    }
}

