import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@services/common/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class HttpAddCredentialsInterceptor implements HttpInterceptor {
    constructor(private configService: ConfigService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const backendUrl = this.configService.getConfig().BASE_API;
        const remoteBackend =
            backendUrl && request.urlWithParams.indexOf(backendUrl) === 0;
        if (remoteBackend) {
            request = request.clone({
                withCredentials: true,
            });
        }

        return next.handle(request);
    }
}
