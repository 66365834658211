import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';

declare let window: any;

@Injectable()
export class CapacitorService {
    constructor(private appAvailability: AppAvailability) {}

    public isNative(): boolean {
        return Capacitor.isNativePlatform();
    }

    public goToProfilTN(): void {
        this.launchExternalApp(
            'com.sncf.android.internal.monportailtransilien',
            'pda-launcher-app://profil/open',
        );
    }

    public launchExternalApp(androidPackageName: string, androidAppUrl: string): void {
        const app = androidPackageName;
        const appUrl = androidAppUrl;

        this.appAvailability.check(app).then(
            isApp => {
                if (isApp) {
                    window.cordova.InAppBrowser.open(appUrl, '_system');
                    console.log('SNCF application available and opened');
                } else {
                    console.log('SNCF application not available, opened website in native browser');
                }
            },
            () => {
                console.log('SNCF application not available, opened marketplace');
            },
        );
    }
}
