import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { ToastController } from '@ionic/angular';
// FAUX POSITIF
// tslint:disable: no-unused-variable
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

import { ConfigService } from './common/config.service';
import { Config } from '@app/models/config.model';

// tslint:enable: no-unused-variable

@Injectable({ providedIn: 'root' })
export class NetworkService {
    public status: number = navigator.onLine ? 1 : 0;
    public subject: Subject<number> = new Subject();
    private currentToast: HTMLIonToastElement = null;
    private checkHealthInterval: number;
    private config: Config;

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private toastController: ToastController,
        private configService: ConfigService) {

        this.config = configService.getConfig();
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status);
            if (status.connected) {
                this.onConnect(true);
            } else {
                this.status = 0;
                this.subject.next(this.status);
                this.message(this.translateService.instant('NETWORK.OFFLINE'));
                if (this.checkHealthInterval) {
                    window.clearInterval(this.checkHealthInterval);
                    this.checkHealthInterval = null;
                }
                this.checkHealthInterval = window.setInterval(() => {
                    this.checkHealth();
                }, 2000);
            }
        });
    }

    public isOnline(): boolean {
        return this.status === 1;
    }

    public isOffline(): boolean {
        return this.status !== 1;
    }

    public getStatus(): Observable<number> {
        return this.subject.asObservable();
    }

    public getStatusText(): 'Online' | 'Offline' {
        return this.status === 1 ? 'Online' : 'Offline';
    }

    public checkHealth() {
        const checkHealthUrl = this.getCheckHealthUrl();
        return this.http.get(checkHealthUrl, { headers: { hideLoader: 'true' } }).subscribe({
            next: (response: any) => {
                if (response.status === 'UP') {
                    this.onConnect();
                }
            },
            error: err => {
                console.error('Erreur lors de la vérification de la disponibilité du serveur,', err);
            },
        });
    }

    private onConnect(showConnectToast = false) {
        if (this.checkHealthInterval) {
            window.clearInterval(this.checkHealthInterval);
            this.checkHealthInterval = null;
        }
        this.status = 1;
        this.subject.next(this.status);
        if (showConnectToast) {
            this.message(this.translateService.instant('NETWORK.ONLINE'));
        }
    }

    private message(message: string) {
        if (this.currentToast) {
            this.currentToast.dismiss().then(() => {
                this.showToast(message);
            });
        } else {
            this.showToast(message);
        }
    }

    private async showToast(message: string) {
        this.currentToast = await this.toastController.create({
            message,
            duration: 3000,
            position: 'bottom',
        });
        this.currentToast.present();
    }

    private getCheckHealthUrl() {
        return `${this.configService.getConfig().BASE_API}api/health`;
    }
}
