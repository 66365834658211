import { last, lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SecurityService } from '@services/common/security.service';

import { ConfigService } from './config.service';
import { SettingsService } from '../settings.service';
import { User } from '@app/models/user.model';

@Injectable()
export class AuthGuardService implements CanActivate {
    private readonly TAG: string = '[AuthGuardService]';
    private user: User = undefined;

    constructor(
        private securityService: SecurityService,
        private settingsService: SettingsService,
        private configService: ConfigService,
        private router: Router,
    ) {
        this.settingsService.getUser().subscribe((user: User) => {
            this.user = user;
        })
    }

    public async canActivate(): Promise<boolean> {
        console.log(this.TAG, 'Checking if already connected');
        if (this.user && this.user.id) {
            console.log(this.TAG, 'User already connected');
            return true;
        }


        const parsed = await this.securityService.meParsed();
        if (parsed) {
            console.log(this.TAG, 'User connected');
            return true;
        }
        console.log(this.TAG, 'User not connected');
        return await this.router.navigate(['/login']);
    }
}
