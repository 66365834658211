import { lastValueFrom, Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NavController, Platform } from '@ionic/angular';
import { ConfigService } from '@services/common/config.service';
import { SNCFID } from 'src/plugins/sncfid';
import { ApiService } from '../api.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root'
})
export class OidcService {
    static adapter: any = {};
    private sdkInitialized: boolean = false;
    private readonly TAG: string = '[OIDCService]';
    private retry = 0;
    private accessToken: string = '';
    private idToken: string = '';

    constructor(private configService: ConfigService, private apiService: ApiService, private platform: Platform) { }

    // FIXME - réactiver le jour où la SNCF souhaite remettre la FID ;-)
    login() {
        window.location.href = this.configService.getConfig().BASE_API + "api/auth/redirect?referer=web&redirectUri=" + this.configService.getConfig().SNCF_ID.REDIRECT_URI;
    }

    public async getAccessToken() {
        await this.checkInit()

        if (await this.checkTokens()) {
            return this.accessToken;
        }

        return undefined;
    }

    public async getIdToken() {
        await this.checkInit()

        if (await this.checkTokens()) {
            return this.idToken;
        }

        return undefined;
    }

    public async login_mobile() {
        if (this.configService.getConfig().SNCF_ID.FEATURE_FLAG !== 'Enabled') {
            return;
        }
        await this.checkInit()

        return await this.connect();
    }

    private async checkInit() {
        if (!this.sdkInitialized) {
            await this.init();
            this.sdkInitialized = true;
        }
    }

    private async init() {
        // if (this.configService.getConfig().SNCF_ID.FEATURE_FLAG !== 'Enabled') return;
        let result: { message: string } = undefined;

        // Local environment initialize sandbox mode
        if (this.configService.getConfig().ENVIRONMENT === 'local') {
            console.log(this.TAG, 'Running in local environment, using sandbox FID');
            result = await SNCFID.initSandbox({
                redirectUrl: '3',
                scopes: 'profile',
                environment: this.configService.getConfig().SNCF_ID.PLATFORM
            });
        } else {
            console.log(this.TAG, 'Running in a non-local environment, connecting usign IDP ', this.configService.getConfig().SNCF_ID);
            result = await SNCFID.initFID({
                redirectUrl: this.configService.getConfig().SNCF_ID.REDIRECT_URI,
                scopes: 'openid',
                environment: this.configService.getConfig().SNCF_ID.PLATFORM,
                clientID: this.configService.getConfig().SNCF_ID.CLIENT_ID,
                clientSecret: this.configService.getConfig().SNCF_ID.CLIENT_SECRET
            });
        }
    }

    private async checkTokens(): Promise<boolean> {
        const status = await SNCFID.isConnected();
        if (!status.connected) {
            const tokens = await SNCFID.getAccessTokens();
            if (tokens.accessToken && tokens.accessToken !== "" && tokens.idToken && tokens.idToken !== "") {
                this.accessToken = tokens.accessToken;
                this.idToken = tokens.idToken;
                return true;
            }
            return await this.connect();
        }

        return true;
    }

    private async connect(): Promise<boolean> {
        try {
            console.log(this.TAG, 'Checking if user is connected');
            const result = await SNCFID.isConnected();
            if (!result.connected) {
                await SNCFID.connect();
                this.retry = 0;
            }
            const info = await SNCFID.getAccessTokens();
            this.accessToken = info.accessToken;
            this.idToken = info.idToken;
            await lastValueFrom(this.apiService.authenticate({
                access_token: this.accessToken
            }));
            console.log(this.TAG, 'User connected');

            return true;
        } catch (err) {
            this.retry++;
            if (this.retry >= 3) {
                console.error(err);
                this.retry = 0;
                throw err;
            } else {
                console.log('retry', this.retry);
                return this.connect();
            }
        }
    }


    public handleError(error: Response | any, observer?: any): Observable<never> {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = (<any>body).error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        if (observer) {
            observer.error(error);
        }
        return observer.throwError(errMsg);
    }
}

