import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { environment } from '@app/env';
import { CapacitorService } from '@app/services/common/capacitor.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { BandeauEnvModule } from '@components/common/bandeau-env/bandeau-env.module';
import { HttpAddCredentialsInterceptor } from '@interceptors/http-add-credentials.interceptor';
import { HttpConfigInterceptor } from '@interceptors/httpconfig.interceptor';
import { IonicModule, IonicRouteStrategy, iosTransitionAnimation, NavParams } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuardService } from '@services/common/auth-guard.service';
import { ConfigService } from '@services/common/config.service';
import { StorageService } from '@services/common/storage.service';
import { PianoAnalyticsService } from '@services/piano-analytics.service';
import player from 'lottie-web';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LottieModule } from 'ngx-lottie';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingModule } from './components/loading/loading.module';
import { OfflineInterceptor } from './interceptors/offline.interceptor';
import { AppInjector } from './services/common/app-injector.service';
import { DownloadService } from './services/download.service';
import { NotificationService } from './services/notification.service';
import { ContactComponent } from './pages/contact/contact.component';
import { lastValueFrom } from 'rxjs';
import { LoginService } from './services/login.service';

registerLocaleData(localeFr);

const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + environment.version);
const appInitializerFn = (injector: Injector, configService: ConfigService, storage: Storage) => async () => {
    AppInjector.setInjector(injector);
    await configService.loadAppConfig();
    await storage.create();
};

const getNgSelectConfig = () => (
    new NgSelectConfig(),
    {
        notFoundText: 'Aucun résultat',
        loadingText: 'Chargement...',
    }
);

const playerFactory = () => player;

const SERVICES = [
    // Services importés
    Storage,
    // Services du projet
    ConfigService,
    StorageService,
    DownloadService,
    CapacitorService,
    PianoAnalyticsService,
    AuthGuardService,
    NotificationService
];

@NgModule({
    declarations: [AppComponent, ContactComponent],
    entryComponents: [],
    exports: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'md',
            navAnimation: iosTransitionAnimation,
        }),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        BandeauEnvModule,
        LoadingModule,
        InlineSVGModule.forRoot(),
        LottieModule.forRoot({ player: playerFactory }),
        IonicStorageModule.forRoot({
            name: 'sam',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        }),
    ],
    providers: [
        ...SERVICES,
        AppAvailability,
        NavParams,
        Network,
        AppVersion,
        ScreenOrientation,
        AndroidPermissions,
        StatusBar,
        SplashScreen,
        Deeplinks,
        FormBuilder,
        Device,
        Keyboard,
        FileOpener,
        SpeechRecognition,
        SocialSharing,
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR',
        },
        {
            provide: APP_BASE_HREF,
            useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
            deps: [PlatformLocation],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [Injector, ConfigService, Storage],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpAddCredentialsInterceptor,
            multi: true,
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            provide: MESSAGE_FORMAT_CONFIG,
            useValue: { locales: ['fr'] },
        },
        {
            provide: ErrorHandler,
        },
        { provide: NgSelectConfig, useFactory: getNgSelectConfig },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
