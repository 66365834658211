import { AbstractComponent } from '@components/abstract/abstract.component';
import { MenuController } from '@ionic/angular';
import { AppInjector } from '@services/common/app-injector.service';

export abstract class AbstractPage extends AbstractComponent {
    public menuCtrl: MenuController;

    constructor() {
        super();
        const injector = AppInjector.getInjector();
        this.menuCtrl = injector.get(MenuController);
    }
}
