import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';
import { StorageService } from '@app/services/common/storage.service';
import { User } from '@models/user.model';
import { AbstractPage } from '@pages/abstract/abstract.page';
import { SecurityService } from '@services/common/security.service';
import { SettingsService } from '@services/settings.service';
import { CapacitorService } from '../../services/common/capacitor.service';
import { Settings } from '@app/models/settings.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends AbstractPage implements OnInit  {
  public utilisateur: User;
  public version: string;
  public dateMaj: string;
  public analyticsDesactive: boolean;
  isNativeApp: boolean;

  constructor(
      private settingsService: SettingsService,
      private securityService: SecurityService,
      private storageService: StorageService,
      public capacitorService : CapacitorService
  ) {
      super();
      this.isNativeApp = this.capacitorService.isNative();
      this.settingsService.getSettings().subscribe((settings: Settings) => {
          this.utilisateur = settings.user;
      })
  }

    ngOnInit() {
      this.version = environment.version;
      this.dateMaj = environment.dateMaj;
      this.storageService.getAnalyticsStatus().then(status => {
          this.analyticsDesactive = status;
      });
  }

  ionViewWillEnter() {
    this.pianoAnalyticsService.trackPageView('Paramétrage contact', 'parametrage/contact');
  }

  public call(): void {
    this.pianoAnalyticsService.trackContact("AssistanceMobiliteCall")
    window.location.href = 'tel:0980980321';
  }

  public mail(): void {
    this.pianoAnalyticsService.trackContact("AssistanceMobiliteMail")
    window.location.href = 'mailto:assistancemobilite@sncf.fr';
  }
  public mailAdmin(): void {
    window.location.href = 'mailto:contact.sam@sncf.fr';
}

  public logout(): void {
      // Désactivation FID depuis SAM V2
      // this.subscriptions.logout = this.openAmService.logout().subscribe({
      //     next: () => {
      this.securityService.logout().then(() => {
          this.navController.navigateRoot('/login');
      }).catch(() => {
          this.toastService.show('Déconnexion #SAM impossible');
      });
      //     },
      //     error: error => {
      //         console.error(error);
      //         this.toastService.show('Déconnexion SNCF ID impossible');
      //     },
      // });
  }

  async sauvegarderAnalytics(): Promise<void> {
      if (this.analyticsDesactive) {
          await this.pianoAnalyticsService.trackEvent('Analytics', {label:'Désactivation tracking analytics'});
          this.storageService.setAnalyticsStatus(this.analyticsDesactive);
      } else {
          this.storageService.setAnalyticsStatus(this.analyticsDesactive);
          await this.pianoAnalyticsService.trackEvent('Analytics', {label:'Activation tracking analytics'});
      }
  }
}
